import { FC, FocusEvent, ReactElement, useEffect, useState } from 'react';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import EditIcon from '../icon/EditIcon';
import { Input } from './Input';
import withSlot, { SlotDefinitions } from '../../../wrappers/withSlot';
import useSlot from '../../../hooks/useSlots';
import PlaceholderTextBox from '../placeholder/PlaceholderTextBox';
import { FormAction, FormConfig } from '../../../models/Form';
import { FormBuilderPlaceholder } from '../../form-builder/FormBuilderTypes';
import { PlaceholderWordProps } from '../placeholder/PlaceholderWord';
import Tooltip from '../Tooltip';

const EMPTY_OBJ = {};

export type InlineEditorProps = {
  id?: string;
  value: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  size?: string;
  suffix?: ReactElement | string | null;
  prefix?: ReactElement | string | null;
  truncatedValue?: string;
  maxLength?: number;
  className?: string;
  inputClassName?: string;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
} & (
  | {
      usePlaceholderTextbox?: false | undefined;
      enableDynamicData?: boolean;
      form?: FormConfig;
      action?: FormAction;
      canCloseEditor?: boolean;
      onPlaceholdersChange?: (placeholders: FormBuilderPlaceholder[]) => void;
      onPlaceholderRemoved?: (placeholder: string) => void;
      wordTagPlugin?: FC<PlaceholderWordProps>;
    }
  | {
      usePlaceholderTextbox: true;
      enableDynamicData: boolean;
      form: FormConfig;
      action: FormAction;
      onPlaceholderRemoved: (placeholder: string) => void;
      canCloseEditor: boolean;
      onPlaceholdersChange: (placeholders: FormBuilderPlaceholder[]) => void;
      wordTagPlugin: FC<PlaceholderWordProps>;
    }
);

const InlineEditor = withSlot<InlineEditorProps, SlotDefinitions<['trailing', 'view']>>((props) => {
  const {
    id,
    value,
    onChange,
    disabled,
    size = 'text-base',
    placeholder,
    suffix,
    truncatedValue,
    maxLength,
    className,
    onBlur,
    onFocus,
    prefix,
    usePlaceholderTextbox,
    canCloseEditor,
    form,
    action,
    onPlaceholderRemoved,
    onPlaceholdersChange,
    enableDynamicData,
    wordTagPlugin,
    inputClassName,
  } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [newValue, setNewValue] = useState(value);

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  const onKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      setIsEditing(false);
      onChange && onChange(newValue);
    }
  };

  const trailingSlot = useSlot(props, 'trailing');

  const viewSlot = useSlot(
    props,
    'view',
    <div className="group flex min-w-full max-w-0 items-center">
      <Tooltip
        text={
          <>
            <span>{prefix}</span> {truncatedValue || newValue || <span className="text-gray-3">{placeholder}</span>}
          </>
        }
      >
        {(tooltip) => (
          <div {...tooltip} className="truncate">
            <span>{prefix}</span>
            {truncatedValue || newValue || <span className="text-gray-3">{placeholder}</span>}
          </div>
        )}
      </Tooltip>
      {!disabled && <EditIcon className="ml-1 h-4 w-4 text-black opacity-0 group-hover:opacity-100" />}
      <span className={`text-dpm-14 mt-4 text-black ${disabled && 'ml-2'}`}>{suffix}</span>
    </div>,
  );

  return (
    <>
      <span
        id={id}
        data-cy="inline-editor-label"
        onClick={!disabled ? () => setIsEditing(!isEditing) : undefined}
        className={`${size} ${disabled ? 'cursor-default' : 'cursor-pointer'} ${className} flex items-center`}
      >
        {!isEditing && viewSlot()}
      </span>
      {isEditing &&
        !disabled &&
        (usePlaceholderTextbox ? (
          <div className="w-full">
            <PlaceholderTextBox
              form={form}
              referencedForms={EMPTY_OBJ}
              action={action}
              enableMarkdown={false}
              enableDynamicData={enableDynamicData}
              initialValue={newValue}
              id={id}
              {...dataAttributeProps(props)}
              autoFocus
              maxLength={maxLength}
              inputPlaceholder={placeholder}
              singleLine
              onKeyPress={onKeyPress}
              onFocus={onFocus}
              onTextChange={(newValue) => {
                setNewValue(newValue);
                onChange && onChange(newValue);
              }}
              onPlaceholdersChange={onPlaceholdersChange}
              onRemovedPlaceholder={onPlaceholderRemoved}
              onBlur={(event) => {
                if (canCloseEditor) {
                  setIsEditing(false);
                }

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onBlur && onBlur(event as any);
              }}
              wordTagPlugin={wordTagPlugin}
            />
          </div>
        ) : (
          <Input
            id={id}
            wrapperClassName="flex-grow max-w-[500px]"
            {...dataAttributeProps(props)}
            value={newValue}
            autoFocus
            maxLength={maxLength}
            placeholder={placeholder}
            onChange={(event) => setNewValue(event.target.value)}
            onKeyPress={onKeyPress}
            onFocus={onFocus}
            className={inputClassName}
            onBlur={(event) => {
              if (isEditing) {
                onChange && onChange(event.target.value);
                setIsEditing(false);
              }
              onBlur && onBlur(event);
            }}
          >
            <Input.Slot name="leading">
              <span className="text-gray-2">{prefix}</span>
            </Input.Slot>
            <Input.Slot name="trailing">{trailingSlot()}</Input.Slot>
          </Input>
        ))}
    </>
  );
});

export default InlineEditor;
